import React, { FC, useState, useEffect, useMemo, useContext, useRef, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { OptionsType } from 'react-select';
import { Grid, List, Button, Typography } from '@material-ui/core';
import { Loading, Panel, Transition } from '../../../../../Components';
import { CheckboxField, DateField, SelectField, TextField } from '../../../../../Components/Field';
import { SelectOption, TypedFormFieldValue } from '../../../../../Components/Form/Form.types';
import { ComplianceReportQuestion } from '../Question';
import { ComplianceReportGroup, ComplianceReportCustomerQuestions } from '../Group';
import { useList } from '../../../../../Hooks/useList';
import { useChecklist } from '../../../../../Hooks/useChecklist';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { PropertyError } from '../../../../../Api/AptorApi';
import { useApiValidationSnackbar } from '../../../../../Api/useValidationSnackbar';
import { useStyles } from './CreateReport.styles';
import { useReportSavedState } from './ReportState';
import moment from 'moment';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { ResultCompliance } from '../Result';
import {
  useParams,
  //  useRouteMatch 
} from "react-router-dom";
// import async from "react-select/async";
import { entityToSelectOption } from "../../../../../Utilities";
export type compliance = 'yes' | 'deficiency' | 'no';

export interface IChecklist {
  name: string;
  items: Array<IQuestion | IGroup>;
  companyUnitQuestions?: IQuestion[];
  date?: any;
  companyUnit?: any;
  unPlanned?: any;
  otherParticipants?: any;
  summary?: any
}

interface IAnswer {
  compliance?: compliance;
  comment?: string;
}

export interface IQuestion extends IAnswer {
  id: number;
  name: string;
  type: 'question';
  hidden: boolean;
  compliance?: any;
  comment?: any;
  groupItems?: any;
}

interface IGroup {
  id: number;
  name: string;
  type: 'group';
  groupItems: IQuestion[];
  compliance?: any;
  comment?: any;
  hidden?: any
}

export interface IReport {
  companyUnitId?: number;
  controlDate?: Date;
  unplanned: boolean;
  participants: number[];
  otherParticipants: string[];
  summary?: string;
  comment?: string;
  questionAnswers: any[];
  companyUnitQuestionAnswers: {
    companyUnitQuestionId: number;
    question: string;
    compliance?: compliance;
    comment?: string;
  }[];
}

interface IReportProps {
  titleKey: string;
  lawId?: number;
  requirementId?: number;
  summaryKey: string;
  storageKey: string;
  loaded: boolean;
  units: OptionsType<SelectOption>;
  fetchComplianceUsers: (companyUnitId: number) => Promise<OptionsType<SelectOption>>;
  checklist?: IChecklist;
  loadingChecklist: boolean;
  disableHide?: boolean;
  submitAction: (unitId: number, reportId: any, a: AptorApi, report: IReport) => Promise<void>;
  onCreateSuccess?: () => void;
  onCompanyUnitChange: (id?: number) => void;
  LawComplianceData?: any,
  setLoaded?: any
}

const renderIntervalDays =
  (classes: Record<'outsideInterval', string>, start?: moment.Moment, end?: moment.Moment) =>
    (
      day: moment.Moment | null,
      _selectedDate: moment.Moment | null,
      _dayInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      const dayIsBetween = day?.isBetween(start, end, undefined, '[]') ?? false;

      return <div className={`${!dayIsBetween ? classes.outsideInterval : ''}`}>{dayComponent}</div>;
    };

export const CreateComplianceReport: FC<IReportProps> = ({
  lawId,
  requirementId,
  titleKey,
  summaryKey,
  storageKey,
  loaded,
  loadingChecklist,
  units,
  fetchComplianceUsers,
  checklist,
  disableHide,
  submitAction,
  onCreateSuccess,
  onCompanyUnitChange,
  LawComplianceData,
  setLoaded
}) => {
  const restored = useRef<boolean>(false);
  const { api, abortController } = useAptorApi();
  const { getCompanyUnitName, customerId } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { notifySuccess, notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const [items, loadItems, updateQuestion] = useChecklist<IQuestion, IGroup>();
  const [companyUnitQuestions, loadCompanyUnitQuestions, , updateCompanyUnitQuestion] = useList<IQuestion>();

  const getUtcNowDate = () => {
    const now = moment(new Date());
    return moment.utc({ year: now.year(), month: now.month(), day: now.date() }).toDate();
  };
  const [date, setDate] = useState<TypedFormFieldValue<Date> | undefined>({ value: getUtcNowDate() });
  const [companyUnit, setCompanyUnit] = useState<TypedFormFieldValue<number> | undefined>();
  const [otherParticipants, setOtherParticipants] = useState<TypedFormFieldValue<number | string>[]>();
  const [summary, setSummary] = useState<TypedFormFieldValue<string>>();
  const [comment, setComment] = useState<TypedFormFieldValue<string>>();
  const [interval, setInterval] = useState<{ start?: moment.Moment; end?: moment.Moment }>();
  const [unplanned, setUnplanned] = useState<boolean>(true);
  const match = useParams<{ planid: string, id: string }>();
  const [users, setUsers] = useState<OptionsType<SelectOption>>();
  const [currentPlanData, setCurrentPlanData] = useState()
  const { updateState, storeState, clearState } = useReportSavedState(
    units,
    fetchComplianceUsers,
    setDate,
    setCompanyUnit,
    setOtherParticipants,
    setSummary,
    storageKey,
    setComment
  );

  const classes = useStyles();
  const path = window.location.pathname?.split("/")
  const checkEdit = path[path.length - 1]
  useEffect(() => {
    onCompanyUnitChange(companyUnit?.value);
    setOtherParticipants(undefined);
  }, [companyUnit, onCompanyUnitChange, clearState]);
  const getplanLaws = async (planid: any) => {
    setLoaded(false)
    await api.searchdirectPlanLaws(planid).then((response: any) => {

      let checkCurrentLaw = response.filter((data: any) => data.id == match.id)
      if (checkCurrentLaw?.length) {
        setCompanyUnit(checkCurrentLaw[0].companyUnits.map(entityToSelectOption)[0])
      }
      setLoaded(true)
    })
  }
  const getPlanRequirement = async (planid: any) => {
    setLoaded(false)
    await api.getPlanRequirement(planid).then((response: any) => {
      let checkCurrentLaw = response.filter((data: any) => data.id == match.id)
      if (checkCurrentLaw?.length) {
        setCompanyUnit(checkCurrentLaw[0].companyUnits.map(entityToSelectOption)[0])
      }
      setLoaded(true)
    })
  }

  const prefillComponyUnit = () => {
    // if(checkEdit!='edit'){
    //   if(path?.length && path[5] === "other-requirements"){
    //     getPlanRequirement(match?.planid)
    //   }else{
    //   getplanLaws(match?.planid)
    //   }
    // }
    setLoaded(false)

    api.getAllcompliancePlan(customerId, { filters: {}, search: "" }).then((response: any) => {
      let checkCurrentplan = response?.customerPlans?.filter((data: any) => data.id == match?.planid)
      if (checkCurrentplan?.length) {
        setCompanyUnit(entityToSelectOption(checkCurrentplan[0].customerCompanyUnitId))
        setUnplanned(checkCurrentplan[0].isDirect)
      }
      setLoaded(true)
    })
  }
  useEffect(() => {
    prefillComponyUnit()
  }, [match])
  useEffect(() => {
    if (checkEdit === 'edit' && LawComplianceData !== "") {
      setLoaded(false)
      if (path?.length && path[5] === "other-requirements") {
        api.getUserCompanyUnitsForRequirementCompliance(Number(match.id)).then((userCompanyUnits) => {
          if (!abortController.current.signal.aborted) {
            const abc: any = userCompanyUnits.items.map(entityToSelectOption)
            const currentcompanyUnit: any = abc?.filter((val: any) => val.value === LawComplianceData?.companyUnit)
            if (currentcompanyUnit?.length) {
              setCompanyUnit(currentcompanyUnit[0])
            }
            const complianceDate: any = moment(LawComplianceData.date)
            setDate({ value: complianceDate })
            // setUnplanned(LawComplianceData.unPlanned)
            setSummary({ value: LawComplianceData.summary })
            setComment({ value: LawComplianceData.comment })
            setLoaded(true)
          }
        })
      } else {
        api.getUserCompanyUnitsForLawCompliance(Number(match.id)).then((userCompanyUnits) => {
          if (!abortController.current.signal.aborted) {
            const abc: any = userCompanyUnits.items.map(entityToSelectOption)
            const currentcompanyUnit: any = abc?.filter((val: any) => val.value === LawComplianceData?.companyUnit)
            if (currentcompanyUnit?.length) {
              setCompanyUnit(currentcompanyUnit[0])
            }
            const complianceDate: any = moment(LawComplianceData.date)
            setDate({ value: complianceDate })
            // setUnplanned(LawComplianceData.unPlanned)
            setSummary({ value: LawComplianceData.summary })
            setComment({ value: LawComplianceData.comment })
            setLoaded(true)
          }
        })
      }
    }
  }, [LawComplianceData])

  useEffect(() => {
    if (checklist) {
      var items = [...checklist.items];
      if (checkEdit === "edit") {
        const updateItems = (item: any) => {
          var updatedItem = { ...item };
          LawComplianceData?.items?.forEach((value: any) => {
            if (updatedItem.id === value.id) {
              updatedItem['hidden'] = value["hidden"] || false;
              if (value["compliance"] != "") {
                updatedItem['compliance'] = value["compliance"];
              }
              if (value["comment"] != "") {
                updatedItem['comment'] = value["comment"];
              }
              if (value["groupItems"]?.length) {
                const updatednestedItem = (nestitem: any) => {
                  var myitem = { ...nestitem }
                  myitem['hidden'] = myitem["hidden"] || false;
                  if (myitem["compliance"] == "") {
                    delete myitem["compliance"]
                  }
                  if (myitem["comment"] == "") {
                    delete myitem["comment"]
                  }
                  myitem['groupItems'] = myitem["groupItems"].map(updatednestedItem);
                  return myitem

                }
                updatedItem['groupItems'] = value["groupItems"].map(updatednestedItem);
              }
            }
          });
          return updatedItem;
        };
        const data = items.map(updateItems);
        items = data;
      }
      const companyUnitQuestionItems = checklist.companyUnitQuestions ? [...checklist.companyUnitQuestions] : [];
      if (restored.current === false) {
        updateState({ items: items, companyUnitQuestions: companyUnitQuestionItems });
        restored.current = true;
      }
      loadItems(items);
      loadCompanyUnitQuestions(companyUnitQuestionItems);
    }
  }, [checklist, loadItems, loadCompanyUnitQuestions, updateState, LawComplianceData]);
  useEffect(() => {
    storeState({
      items,
      companyUnitQuestions,
      date: date?.value,
      companyUnit: companyUnit?.value,
      otherParticipants: otherParticipants?.map((x) => x.value) ?? [],
      summary: summary?.value,
      comment: comment?.value
    });
  }, [storeState, items, companyUnitQuestions, date, companyUnit, otherParticipants, summary, comment]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (companyUnit) {
        const result = await fetchComplianceUsers(companyUnit.value);
        setUsers(result);
      }
    };

    fetchUsers();
  }, [fetchComplianceUsers, companyUnit]);

  useEffect(() => {
    const fetchInterval = async () => {
      if (lawId && companyUnit) {
        const result = await api.getLawComplianceInterval(lawId, companyUnit.value);
        setInterval({
          start: result.start ? moment(result.start) : undefined,
          end: result.end ? moment(result.end) : undefined,
        });
      }
      if (requirementId && companyUnit) {
        const result = await api.getRequirementComplianceInterval(requirementId, companyUnit.value);
        setInterval({
          start: result.start ? moment(result.start) : undefined,
          end: result.end ? moment(result.end) : undefined,
        });
      }
    };
    fetchInterval();
  }, [companyUnit, api, lawId, requirementId]);

  const isOutsideInterval = useMemo(() => {
    if (!interval || (!interval.start && !interval.end)) return false;
    let isBeforeCopmliance = interval.start?.isAfter(date?.value);
    let isAfterCompliance = interval.end && moment(interval?.end)?.add(1, 'day')?.isBefore(date?.value);
    return isBeforeCopmliance || isAfterCompliance || false;
  }, [date, interval]);
  const answerIsInvalid = (answer: IAnswer, hidden: boolean) => {
    if (hidden === true) {
      return false;
    } else if (answer.compliance === undefined) {
      return false;
    }
    return answer.compliance !== 'yes' && (answer.comment === undefined || answer.comment.length === 0);
  };

  const disableSave = useMemo(() => {
    if (date?.value === undefined || companyUnit?.value === undefined) {
      return true;
    }

    return (
      items.some((item) => {
        if (item.type.toLowerCase() === 'question') {
          return answerIsInvalid(item, item.hidden);
        } else if (item.type.toLowerCase() === 'group') {
          return item.groupItems.some((gi: any) => answerIsInvalid(gi, gi.hidden));
        }
        return false;
      }) ||
      companyUnitQuestions.some((companyUnitQuestion) => {
        return answerIsInvalid(companyUnitQuestion, false);
      })
    );
  }, [items, companyUnitQuestions, date, companyUnit]);

  const submit = async () => {
    const companyUnitId = companyUnit?.value;
    if (companyUnitId !== undefined) {
      let reportId = ""
      const path = window.location.pathname?.split("/")
      const checkEdit = path[path.length - 1]
      if (checkEdit === 'edit') {
        reportId = LawComplianceData?.reportId
      }
      const action = (aptorApi: AptorApi) =>
        submitAction(companyUnitId, reportId, aptorApi, {
          companyUnitId: companyUnitId,
          controlDate: date?.value,
          unplanned: unplanned,
          participants:
            otherParticipants && otherParticipants.length
              ? otherParticipants.filter((x) => typeof x.value === 'number').map((x) => +x.value)
              : [],
          otherParticipants:
            otherParticipants && otherParticipants.length
              ? otherParticipants.filter((x) => typeof x.value === 'string').map((x) => `${x.value}`)
              : [],
          summary: summary?.value,
          comment: comment?.value,
          questionAnswers: items.map(itemToDtos).reduce((a, b) => a.concat(b), []),
          companyUnitQuestionAnswers: companyUnitQuestions.map((companyUnitQuestion) => ({
            companyUnitQuestionId: companyUnitQuestion.id,
            question: companyUnitQuestion.name,
            compliance: companyUnitQuestion.compliance,
            comment: companyUnitQuestion.comment,
          })),
        }).then(async (res: any) => {
          if (checkEdit === 'edit') {
            notifySuccess();
            clearState();
            onCreateSuccess && onCreateSuccess();
          } else {
            await api.createplanReport(Number(match.planid), Number(res.id), Number(match.id))
              .then(() => {
                notifySuccess();
                clearState();
                onCreateSuccess && onCreateSuccess();
              })
          }
        });

      const onValidation = (errors: PropertyError[]) => notifyValidationErrors(errors);
      await api.invoke(action, abortController.current, onValidation, notifyUnhandledException);
    }
  };

  const itemToDtos = (item: IQuestion | IGroup): any[] => {
    if (item.type.toLowerCase() === 'question') {
      return [
        {
          questionId: item.id,
          question: item.name,
          hidden: item.hidden,
          group: null,
          compliance: item.compliance,
          comment: item.comment,
        },
      ];
    } else {
      return item.groupItems.map((groupItem: any) => ({
        questionId: groupItem.id,
        question: groupItem.name,
        hidden: groupItem.hidden,
        group: item.name,
        compliance: groupItem.compliance,
        comment: groupItem.comment,
      }));
    }
  };

  const confirmedChangeWarning = useRef<boolean>(true);

  useEffect(() => {
    if (companyUnit) {
      confirmedChangeWarning.current = false;
    } else {
      confirmedChangeWarning.current = true;
    }
  }, [companyUnit]);
  const { deleteConfirmation } = useConfirmation();
  const accept = useCallback(async () => {
    confirmedChangeWarning.current = true;
  }, []);
  const handleCompanyUnitWarning = useCallback(() => {
    if (confirmedChangeWarning.current) {
      return;
    }
    const confirmation = {
      title: formatMessage(
        { id: 'law-portal.compliance.form.change-warning.label' },
        { companyUnit: getCompanyUnitName(formatMessage).toLowerCase() },
      ),
      description: formatMessage(
        { id: 'law-portal.compliance.form.change-warning.confirmation' },
        { companyUnit: getCompanyUnitName(formatMessage).toLowerCase() },
      ),
    };

    deleteConfirmation(accept, { ...confirmation, hideSavedNotification: true, hideCancel: true, name: undefined });
  }, [formatMessage, deleteConfirmation, accept, getCompanyUnitName]);

  if (!loaded) {
    return <Loading />;
  }

  function flattenGroupItems(data: any[]): any[] {
    return data?.flatMap(item =>
      item?.type === "group" && item.groupItems
        ? flattenGroupItems(item.groupItems)
        : item
    );
  }
  const flattenedData = flattenGroupItems(items);
  const total = flattenedData.filter((item) => item?.hidden == false || item?.hidden == undefined).length || 0;
  const yesCount = flattenedData.filter((item) => (item as IQuestion | IAnswer)?.compliance === "yes").length || 0;
  const noCount = flattenedData.filter((item) => (item as IQuestion | IAnswer)?.compliance === "no").length || 0;
  const deficiencyCount = flattenedData.filter((item) => (item as IQuestion | IAnswer)?.compliance === "deficiency").length || 0;
  const yesPercentage = parseFloat(((yesCount / (yesCount + noCount + deficiencyCount)) * 100).toFixed(2)) || 0;
  return (
    <Panel titleKey={titleKey}>
      {/* {unplanned === false && interval && (interval.start || interval.end) && (
        <Typography variant="h4" component="h3" style={{ marginBottom: '1em' }}>
          {formatMessage({ id: 'law-portal.compliance.form.planned' })} {formatMessage({ id: titleKey })}:{' '}
          {interval?.start?.format('ll') ?? ''} - {interval?.end?.format('ll') ?? ''}
        </Typography>
      )} */}
      <Grid container spacing={2} >
        <Grid item sm={5} xs={12}>
          {companyUnit ? (
            <SelectField
              required
              name="company-unit"
              label={getCompanyUnitName(formatMessage)}
              errorTexts={[]}
              initialState={companyUnit}
              options={units}
              disable={true}
              onChange={setCompanyUnit}
              onMenuOpen={handleCompanyUnitWarning}
            />
          ) : (
            <SelectField
              required
              name="company-unit"
              label={getCompanyUnitName(formatMessage)}
              errorTexts={[]}
              initialState={companyUnit}
              options={units}
              disable={checkEdit === "edit" ? true : false}
              onChange={setCompanyUnit}
              onMenuOpen={handleCompanyUnitWarning}
            />
          )}
        </Grid>
        <Grid item sm={5} xs={12}>
          <DateField
            required
            onRenderDay={
              (interval?.start || interval?.end) && unplanned === false
                ? renderIntervalDays(classes, interval.start, interval.end)
                : undefined
            }
            name="date"
            label={formatMessage({ id: 'law-portal.compliance.form.date.label' })}
            errorTexts={[]}
            // helperText={
            //   ((unplanned === false && isOutsideInterval && (
            //     <Typography variant="caption" className={classes.warningText}>
            //       {formatMessage({ id: 'law-portal.compliance.form.date.warning' })}
            //     </Typography>
            //   )) ||
            //     undefined) as any
            // }
            initialState={date}
            onChange={setDate}
          />
        </Grid>

        {users && (
          <Grid item sm={5} xs={12}>
            <SelectField
              isMulti
              creatable
              createLabel={formatMessage({ id: 'law-portal.compliance.form.other-participant' })}
              name="participants"
              label={formatMessage({ id: 'law-portal.compliance.form.other-participants.label' })}
              errorTexts={[]}
              initialState={otherParticipants}
              key={otherParticipants?.map((x) => x.value).join('-')}
              options={users}
              onChange={setOtherParticipants}
            />
          </Grid>
        )}
        {/* <Grid item sm={7} xs={12} alignItems='center' style={{ paddingTop: 30 }}>
          <CheckboxField
            label={formatMessage({ id: 'law-portal.compliance.form.unplanned.label' })}
            name="unplanned"
            errorTexts={[]}
            initialValue={unplanned}
            onChange={(val) => setUnplanned(val?.value ?? false)}
          />
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            name="summary"
            label={formatMessage({ id: summaryKey })}
            rows={8}
            multiline
            errorTexts={[]}
            initialState={summary}
            onChange={setSummary}
          />
        </Grid>
      </Grid>
      {companyUnit && (
        <Transition isLoading={loadingChecklist} hideSpinner>
          <List className={classes.list}>
            {items.map((item: any, index: any) =>
              item.type.toLowerCase() === 'question' ? (
                <ComplianceReportQuestion
                  key={`question-${item.id}`}
                  {...item}
                  index={index + 1}
                  disableHide={disableHide}
                  onChange={updateQuestion}
                />
              ) : (
                <ComplianceReportGroup
                  key={`group-${item.id}`}
                  {...item}
                  index={index + 1}
                  disableHide={disableHide}
                  onChange={updateQuestion}
                />
              ),
            )}

            {companyUnitQuestions.length > 0 && (
              <ComplianceReportCustomerQuestions
                key="customer-questions"
                questions={companyUnitQuestions}
                onChange={updateCompanyUnitQuestion}
              />
            )}
          </List>
          <ResultCompliance complied={yesCount} deficiency={deficiencyCount} deviation={noCount} fulfilled={yesPercentage} />
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <TextField
                name="comment"
                label={formatMessage({ id: "law-portal.our-laws.compliance.comment" })}
                rows={8}
                multiline
                errorTexts={[]}
                initialState={comment}
                onChange={setComment}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button disabled={disableSave} variant="outlined" onClick={submit}>
                  <FormattedMessage id="form.save" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Transition>
      )}
    </Panel>
  );
};
