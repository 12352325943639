import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, Module, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { HtmlView, Panel } from '../../../../../../Components';
import { ApplicationHiddenForm } from './Application.Hidden.Form';
import { ICompanyUnitLaw } from '.';
import { useStyles } from './Application.styles';
import { Title } from '../../../../../../Components/Panel/Panel';
import { ExpandMore, UnfoldLess, UnfoldMore } from '@material-ui/icons';

interface IProps {
  lawId: number;
  application: any[];
  companyUnits: ICompanyUnitLaw[];
  onChange: () => void;
}

export const Application: FC<IProps> = ({ lawId, application, children, companyUnits, onChange }) => {
  const { hasAccessToFeature, getCompanyUnitName, hasAccessToModule } = useContext(UserContext);
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [displayForm, setDisplayForm] = useState<any[]>([]);
  const [panelOpen, setPanelOpen] = useState<any[]>([])
  const [applicationData, setApplicationData] = useState<any[]>([])
  // const hidden = companyUnits.every((x) => x.applicationHidden);
  // const partiallyHidden =
  //   companyUnits.some((x) => x.applicationHidden) && companyUnits.some((x) => !x.applicationHidden);
  // const canHideApplication = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  function allCompanyUnitsHidden(companyUnits:any, hiddenForCompanyUnits:any) {
    return companyUnits.every((companyUnit:any) => 
      hiddenForCompanyUnits.some((hiddenUnit:any) => 
        hiddenUnit.id === companyUnit.id
      )
    );
  }
  useEffect(() => {
    const data = application.filter((val) => {
      if (
        (val?.status === 1 ||
          (val?.status === 0 && hasAccessToModule(Module.Administration))) &&
        !(allCompanyUnitsHidden(companyUnits ,val?.hiddenForCompanyUnits) &&
          !hasAccessToModule(Module.Administration))
      ) {
        return val;
      }
    });
  
    setApplicationData(data);
  }, [application, companyUnits]);
  const handleOnChange = (applicationId: any) => {
    // setDisplayForm(false);
    let displayFormHide = displayForm.filter((item) => item != applicationId)
    setDisplayForm(displayFormHide)
    onChange();
  };
  useEffect(() => {
    if (applicationData.length&&!panelOpen.length) {
      setPanelOpen([applicationData[0].id])
    }
  }, [applicationData])

  // if (hidden && !canHideApplication) {
  //   return null;
  // }
  const handleFormDisplay = (applicationID: number) => {
    setDisplayForm([...displayForm, applicationID])
  }
  const handleCencleButton = (applicationID: number) => {
    let displayFormHide = displayForm.filter((item) => item != applicationID)
    setDisplayForm(displayFormHide)
  }
  const handleHiddenCompanyUnits = (val: any) => {
    const hiddenForCompanyUnits = val.hiddenForCompanyUnits?.map((item: any) => item.id);
    return companyUnits.map((unit) => ({
      ...unit,
      applicationHidden: hiddenForCompanyUnits?.includes(unit.id) ?? false,
    }));
  };

  return (
    <Panel titleKey="law-portal.our-laws.overview.application.label" actionIcon={application?.length != panelOpen.length ? <div onClick={() => setPanelOpen(application?.map((item: any) => item.id))}><UnfoldMore style={{ cursor: 'pointer' }} /></div> : <div onClick={() => setPanelOpen([])}><UnfoldLess style={{ cursor: 'pointer' }} /></div>} >
      {children}
      <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} item>
        {applicationData?.map((val: any, index) => {
          const companyUnitsData = handleHiddenCompanyUnits(val);
          const hidden = companyUnitsData.every((x) => x.applicationHidden);
          const canHideApplication = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
          const partiallyHidden =
            companyUnitsData.some((x) => x.applicationHidden) && companyUnitsData.some((x) => !x.applicationHidden);
          return (
              <ExpansionPanel
                defaultExpanded={false}
                key={index}
                expanded={panelOpen.includes(val.id)}
                onChange={() => {
                  setPanelOpen((prev) =>
                    prev.includes(val.id) ? prev.filter((id) => id !== val.id) : [...prev, val.id]
                  );
                }}
              >
                <ExpansionPanelSummary style={{ pointerEvents: "none" }} expandIcon={<ExpandMore style={{ pointerEvents: 'auto' }} />}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <div style={(val?.status == 0||allCompanyUnitsHidden(companyUnits ,val?.hiddenForCompanyUnits)) ? { color: 'gray' } :{}}><Title title={`${index + 1} ${val?.heading?val?.heading:formatMessage({id:"lawportal.null.heding.text"})}`} /></div>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails  >
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      {(hidden === false || canHideApplication) && <HtmlView value={val?.application} />}
                    </Grid>
                    <Grid item>
                      {canHideApplication && displayForm.includes(val.id) ? (
                        <ApplicationHiddenForm
                          lawId={lawId}
                          companyUnits={companyUnitsData}
                          applicationId={val.id}
                          onChange={handleOnChange}
                          onCancel={() => handleCencleButton(val.id)}
                        />
                      ) : ""}
                      {!displayForm.includes(val.id) && (
                        <Grid container justify="space-between">
                          <Grid item className={classes.partiallyHidden}>
                            {partiallyHidden && (
                              <FormattedMessage
                                id="law-portal.our-laws.overview.application.partially-visible"
                                values={{
                                  unitsName: getCompanyUnitName(formatMessage, true),
                                  hiddenUnits: companyUnitsData
                                    .filter((x) => x.applicationHidden)
                                    .map((x) => x.name)
                                    .join(', '),
                                }}
                              />
                            )}
                          </Grid>
                          {canHideApplication && (
                            <Grid item>
                              <Button variant="outlined" onClick={() => handleFormDisplay(val.id)}>
                                <FormattedMessage id="law-portal.our-laws.overview.application.toggle-visibility.button" />
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )

        })}
      </Grid>

    </Panel >
  );
};
